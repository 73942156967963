import FiveScreen from '../../FiveScreen/FiveScreen';
import instrum from './../../img/HopClean/article6.jpg';
import instrum2 from './../../img/HopClean/article62.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articelsix">En</Link>
          ) : (
            <Link to="/articelsix">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Сzyszczenie materacy w przedszkolach Sosnowiec</h2>
            <p>
              Materace w przedszkolach są intensywnie używane przez dzieci przez
              większość dnia. Z tego powodu, konieczne jest zachowanie
              odpowiedniej higieny i regularne czyszczenie materacy, aby
              zapewnić bezpieczeństwo i zdrowie dzieci. W tym artykule omówimy,
              jak często należy czyścić materac w przedszkolu i jakie metody
              należy stosować.
            </p>
            <p>
              Przede wszystkim, należy pamiętać, że materace w przedszkolach są
              narażone na różnego rodzaju zanieczyszczenia, w tym na mocz, pot,
              ropa i bakterie. Z tego powodu, należy regularnie czyścić
              materace, aby uniknąć rozwoju pleśni, roztoczy i bakterii. Zgodnie
              z zaleceniami specjalistów, materace powinny być czyszczone co
              najmniej dwa razy w roku.
            </p>
            <img
              className="quilon"
              src={instrum2}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              Jednym ze sposobów na utrzymanie czystości materaca jest regularne
              odkurzanie, które pozwala na usunięcie kurzu i brudu. W tym celu,
              można wykorzystać odkurzacz z filtrem HEPA, który usuwa alergeny i
              pyły z powietrza, a także usuwa zabrudzenia z powierzchni
              materaca.
            </p>

            <p>
              Kolejną metodą czyszczenia materaca jest użycie środków
              dezynfekujących, takich jak płyny do czyszczenia dywanów i mebli.
              Należy jednak pamiętać, że niektóre środki czyszczące mogą być
              szkodliwe dla dzieci, dlatego ważne jest, aby wybierać produkty,
              które są bezpieczne dla dzieci i nie zawierają szkodliwych
              substancji.
            </p>
            <p>
              Alternatywną metodą czyszczenia materaca jest stosowanie pary
              wodnej. Para wodna pozwala na usunięcie zarówno brudu, jak i
              bakterii, bez użycia środków chemicznych. Jednak, w przypadku
              użycia pary wodnej, należy pamiętać o tym, aby materac był
              odpowiednio wysuszony przed ponownym użyciem.
            </p>
            <img
              className="quilon"
              src={instrum}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              W przypadku poważniejszych zabrudzeń i plam, najlepiej skorzystać
              z usług specjalistycznych firm czyszczących, które posiadają
              odpowiednie narzędzia i środki czyszczące. Firmy te często
              korzystają z profesjonalnych maszyn do czyszczenia materacy, które
              pozwala na usunięcie trudniejszych plam i zabrudzeń.
            </p>

            <p>
              Podsumowując, materace w przedszkolach należy regularnie odkurzać
              i dezynfekować, aby utrzymać ich czystość i uniknąć rozwoju
              pleśni, roztoczy i bakterii. Zaleca się czyszczenie materacy co
              najmniej dwa razy w roku, a w przypadku poważniejszych zabrudzeń,
              skorzystanie z usług specjalistycznych firm czyszczących.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
