import React from 'react';
// import Choose from './Choose';
import './ContainerElements.css';
import { Link } from 'react-router-dom';
import Service from './Service';

function ContainerH2H3() {
  return (
    <>
      <h3 className="h3">PROFESSIONAL WASHING and drying</h3>
      <h1 className="h1">
        COUCHES, CHAIRS, MATRESSES, STROLLERS, CURTAINS, BLINDS, CARPETS,
        CARPETS, CAR UPHOLSTERY...
      </h1>
      <h2 className="h2">
        👉 WE GUARANTEE A 100% REFUND IF THE CUSTOMER IS NOT SATISFIED WITH THE
        WASHING EFFECT!!!
      </h2>
    </>
  );
}
function Check() {
  return (
    <div className="check">
      <li>
        <Link to="/en/cennik">More details...</Link>
      </li>
    </div>
  );
}
function ContainerElements() {
  return (
    <div className="container">
      <ContainerH2H3 />
      <Service />
      {/* <Choose /> */}
      <Check />
    </div>
  );
}

export default ContainerElements;
