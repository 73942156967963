import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto6.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel19">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Jak samodzielnie wyczyścić pluszowe zabawki w warunkach domowych
              Sosnowiec
            </h2>
            <p>
              Pluszowe zabawki są ulubieńcami dzieci i często stają się ich
              towarzyszami codziennych przygód. Niestety, z czasem gromadzą one
              brud, kurz i bakterie, dlatego regularne czyszczenie jest
              niezbędne.{' '}
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Oto kilka sprawdzonych metod na skuteczne i bezpieczne
              wyczyszczenie pluszowych zabawek w domu.
            </p>
            <ul className="decical-ol">
              <li>
                Sprawdzenie etykiety. Zanim przystąpisz do czyszczenia, zawsze
                sprawdź etykietę z instrukcjami producenta. Często znajdziesz
                tam informacje, czy zabawka nadaje się do prania w pralce, czy
                wymaga ręcznego czyszczenia. Etykieta może również zawierać
                wskazówki dotyczące odpowiednich temperatur i detergentów.
              </li>
              <li>
                Pranie w pralce. Jeśli etykieta na to pozwala, pranie w pralce
                jest najwygodniejszym sposobem czyszczenia pluszowych zabawek.
                <ul>
                  <li>
                    Przygotowanie zabawki: Upewnij się, że wszystkie elementy
                    plastikowe lub elektroniczne są usunięte, jeśli to możliwe.
                    Zabawki z małymi częściami lub luźnymi elementami najlepiej
                    umieścić w woreczku na pranie, aby zapobiec uszkodzeniom.
                  </li>
                  <li>
                    Wybór programu prania: Wybierz delikatny program prania z
                    niską temperaturą (maksymalnie 30°C) i użyj łagodnego
                    detergentu. Unikaj wybielaczy i silnych środków chemicznych.
                  </li>
                  <li>
                    Suszenie: Po zakończeniu prania zabawkę można wysuszyć na
                    powietrzu. Unikaj suszenia w suszarce, ponieważ wysoka
                    temperatura może uszkodzić tkaninę i kształt zabawki. Możesz
                    delikatnie wycisnąć nadmiar wody ręcznikiem i pozostawić
                    zabawkę do wyschnięcia na świeżym powietrzu.
                  </li>
                </ul>
              </li>
              <li>
                Czyszczenie ręczne Jeśli zabawka nie nadaje się do prania w
                pralce, najlepiej jest wyczyścić ją ręcznie.
                <ul>
                  <li>
                    Przygotowanie roztworu czyszczącego: W misce wymieszaj
                    ciepłą wodę z łagodnym detergentem lub mydłem dla dzieci.
                  </li>
                  <li>
                    Czyszczenie: Za pomocą miękkiej gąbki lub szczoteczki do
                    zębów delikatnie przetrzyj całą powierzchnię zabawki,
                    zwracając szczególną uwagę na zabrudzone miejsca. Unikaj
                    nadmiernego moczenia, aby nie zniszczyć wewnętrznego
                    wypełnienia.
                  </li>
                  <li>
                    Spłukiwanie: Z wilgotną ściereczką przetrzyj zabawkę, aby
                    usunąć resztki detergentu. Upewnij się, że nie pozostają
                    żadne ślady mydła, które mogą podrażniać skórę dziecka.
                  </li>
                  <li>
                    Suszenie: Pozostaw zabawkę do wyschnięcia na świeżym
                    powietrzu. Możesz umieścić ją w przewiewnym miejscu lub
                    powiesić na suszarce, unikając bezpośredniego
                    nasłonecznienia.
                  </li>
                </ul>
              </li>
              <li>
                Dezynfekcja Aby zdezynfekować zabawkę i pozbyć się bakterii oraz
                roztoczy, można użyć kilku prostych metod:
                <ul>
                  <li>
                    Zamrażarka: Umieść zabawkę w plastikowym worku i włóż do
                    zamrażarki na 24 godziny. Niska temperatura zabije większość
                    drobnoustrojów.
                  </li>
                  <li>
                    Soda oczyszczona: Posyp zabawkę sodą oczyszczoną, a
                    następnie delikatnie wmasuj ją w tkaninę. Po kilku godzinach
                    wytrzep lub odkurz zabawkę, aby usunąć pozostałości sody.
                  </li>
                  <li>
                    Ocet: Woda z dodatkiem octu (proporcja 1:1) to skuteczny
                    środek dezynfekujący. Zwilż ściereczkę w takim roztworze i
                    przetrzyj powierzchnię zabawki.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Regularne czyszczenie pluszowych zabawek nie tylko przedłuża ich
              żywotność, ale także zapewnia higieniczne warunki dla Twojego
              dziecka. Dzięki powyższym metodom możesz skutecznie i bezpiecznie
              zadbać o czystość ulubionych pluszaków w domowych warunkach.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
