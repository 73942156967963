import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto10.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel15">En</Link>
          ) : (
            <Link to="/articel15">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Jak samodzielnie wyczyścić meble skórzane Sosnowiec </h2>
            <p>
              Meble skórzane to elegancki i trwały dodatek do każdego wnętrza.
              Aby jednak zachować ich piękno i funkcjonalność na długie lata,
              konieczne jest odpowiednie czyszczenie i konserwacja.
            </p>
            <img className="window" src={window} title="window" alt="window" />

            <p>
              Poniżej znajdziesz szczegółowy przewodnik, jak samodzielnie
              wyczyścić meble skórzane:
            </p>
            <ul className="decical-ol">
              <li>
                Przygotowanie. Materiały potrzebne:
                <ul>
                  <li>Miękka ściereczka z mikrofibry</li>
                  <li>Odkurzacz z końcówką szczelinową</li>
                  <li>
                    Łagodny detergent (np. mydło do skóry lub delikatny płyn do
                    naczyń)
                  </li>
                  <li>Woda destylowana</li>
                  <li>Skórzany balsam lub krem konserwujący</li>
                  <li>Miska</li>
                </ul>
              </li>
              <li>
                Odkurzanie. Zacznij od dokładnego odkurzenia mebli. Użyj
                odkurzacza z końcówką szczelinową, aby usunąć kurz i brud z
                zakamarków i szwów. Odkurzanie pomoże usunąć luźne
                zanieczyszczenia, które mogłyby porysować skórę podczas dalszego
                czyszczenia.
              </li>
              <li>
                Przygotowanie roztworu czyszczącego. W misce zmieszaj niewielką
                ilość łagodnego detergentu z wodą destylowaną. Woda destylowana
                jest lepsza od kranowej, ponieważ nie zawiera minerałów, które
                mogłyby osadzać się na skórze.
              </li>
              <li>
                Czyszczenie powierzchni. Zanurz miękką ściereczkę z mikrofibry w
                przygotowanym roztworze, a następnie dobrze ją wyciśnij, aby
                była tylko lekko wilgotna. Delikatnie przetrzyj powierzchnię
                mebli skórzanych, wykonując okrężne ruchy. Unikaj nadmiernego
                moczenia skóry, ponieważ może to prowadzić do uszkodzeń.
              </li>
              <li>
                Usuwanie plam. W przypadku trudniejszych plam, takich jak
                tłuszcz czy atrament, możesz użyć specjalistycznych środków do
                usuwania plam ze skóry dostępnych w sklepach. Zawsze testuj
                środek na niewidocznej części mebli, aby upewnić się, że nie
                uszkodzi on materiału.
              </li>
              <li>
                Pielęgnacja i konserwacja. Po dokładnym wyczyszczeniu mebli,
                pozostaw je do wyschnięcia w naturalny sposób, z dala od
                bezpośredniego źródła ciepła i promieni słonecznych. Gdy skóra
                jest sucha, nałóż na nią skórzany balsam lub krem konserwujący.
                Nanieś niewielką ilość preparatu na miękką ściereczkę i
                delikatnie wmasuj w skórę. Tego typu produkty pomagają nawilżyć
                skórę, zapobiegając jej pękaniu i wysychaniu.
              </li>
              <li>
                Regularna konserwacja. Aby meble skórzane zachowały swój wygląd
                i właściwości przez długie lata, regularnie je odkurzaj i czyść
                zgodnie z powyższymi krokami. Przynajmniej raz na pół roku
                używaj specjalistycznych preparatów do konserwacji skóry.
              </li>
            </ul>
            <p>
              Samodzielne czyszczenie mebli skórzanych nie jest trudne, jeśli
              zastosujesz się do powyższych wskazówek. Kluczowe jest regularne
              dbanie o skórę, aby zachowała swój wygląd i funkcjonalność na
              długie lata. Dzięki odpowiedniej pielęgnacji, twoje meble skórzane
              będą cieszyć oko i służyć przez wiele lat.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
