import window from './../../img/HopClean/articel10.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import FirstScreenEn from '../../FirstScreenEn/ContainerOne';
import FiveSCreenEn from '../../FiveScreenEn/FiveScreen';

import '../../FirstScreenEn/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>En</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/articel10">Pl</Link>
          ) : (
            <Link to="/en/articel10">En</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Laundry and cleaning of upholstered furniture on yachts and boats{' '}
            </h2>
            <p>
              Laundry and cleaning of upholstered furniture on yachts and boats
              in Sosnowiec - bring your interiors to life!
            </p>
            <p>
              Do you dream of a modern, elegant and fragrant yacht or boat? Or
              maybe you dreamed of a dream cruise on the waters of crystal clear
              lakes or the sea? In order for your yacht or boat to always look
              like new, take care of their interiors! We offer professional
              washing and cleaning of upholstered furniture on yachts and boats
              in Sosnowiec , so that you can enjoy comfort and luxury on board.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Our qualified specialists have extensive experience in cleaning
              and washing upholstered furniture on yachts and boats. Thanks to
              the use of the latest techniques and professional cleaning agents,
              we will restore your furniture to its original splendor. We will
              remove stains, dirt, dust and unpleasant odors so that your
              upholstered furniture looks like new.
            </p>
            <p>
              Washing and cleaning upholstered furniture on yachts and boats in
              Sosnowiec is not only a matter of aesthetics, but also hygiene.
              The interiors of yachts and boats are exposed to moisture, dirt
              and bacteria, which can lead to mold and other adverse health
              conditions. Therefore, it is worth taking care of regular washing
              and cleaning of upholstered furniture to keep it clean and fresh
              on board.
            </p>
            <p>Why is it worth using our services? Here are some reasons:</p>
            <ul className="windowraz">
              <li>
                Professionalism – Our team consists of experienced specialists
                who have knowledge and skills in the field of washing and
                cleaning upholstered furniture on yachts and boats.
              </li>
              <li>
                Unique approach – We treat each order individually, matching the
                cleaning methods and agents to the type of upholstered furniture
                and the degree of soiling.
              </li>
              <li>
                Safety – We use professional cleaning agents that are effective
                in removing dirt, but at the same time safe for human and animal
                health.
              </li>
              <li>
                Convenience – We act quickly and efficiently, adapting to your
                schedule and needs. We can wash and clean upholstered furniture
                on site, on your yacht or boat, without having to dismantle it.
              </li>
              <li>
                Efficiency - With our professional tools and techniques, we
                achieve excellent results in removing stains, dirt and
                unpleasant odors from upholstered furniture on yachts and boats,
                leaving them fresh, clean and pleasant to the touch.
              </li>
              <li>
                Investment protection – Yachts and boats are often expensive
                investments, so it is worth taking care of their equipment,
                including upholstered furniture. Regular washing and cleaning of
                upholstered furniture allows you to maintain its quality,
                extends its life and protects against premature wear.
              </li>
              <li>
                Professional advice – Our team will be happy to advise you on
                the care of upholstered furniture on yachts and boats. We will
                advise you on how to take care of your furniture to keep it in
                the best condition, and we will recommend the right cleaning
                agents for the care of upholstered furniture, which will allow
                you to enjoy it for a long time.
              </li>
              <li>
                Competitive prices - We offer competitive prices for our
                services of washing and cleaning upholstered furniture on yachts
                and boats in Sosnowiec . It is worth investing in professional
                cleaning that will allow you to maintain the luxurious look and
                comfort of the interior of your yacht or boat.
              </li>
            </ul>
            <p>
              don't wait! Take care of the cleanliness and appearance of
              upholstered furniture on your yacht or boat in Sosnowiec today.
              Take advantage of our professional washing and cleaning services
              for upholstered furniture and enjoy freshness, comfort and luxury
              on board! Contact us now to arrange a free quote and take
              advantage of our services.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreenEn />
      <Lang />
      <Furnitura />
      <FiveSCreenEn />
    </div>
  );
}
export default ArticelTwo;
