import FiveScreen from '../../FiveScreen/FiveScreen';
import regul from './../../img/HopClean/article5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articelfive">En</Link>
          ) : (
            <Link to="/articelfive">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie dywanów i wykładzin Sosnowiec</h2>
            <p>
              Pranie dywanów i wykładzin to ważna czynność, która wpływa na
              czystość i higienę naszych domów. Pomimo regularnego odkurzania,
              na powierzchniach tych mogą gromadzić się różnego rodzaju
              zanieczyszczenia, które wpływają nie tylko na estetykę, ale
              również na nasze zdrowie. W tym artykule przedstawimy kilka
              powodów, dla których pranie dywanów i wykładzin jest tak istotne.
            </p>
            <p>
              Po pierwsze, pranie dywanów i wykładzin pomaga usunąć
              zanieczyszczenia, takie jak bakterie, wirusy, roztocza, kurz czy
              pyłki roślin. Te czynniki mogą wpływać na nasze zdrowie, zwłaszcza
              na osoby z alergiami i astmą. Regularne pranie dywanów i wykładzin
              może pomóc w zmniejszeniu wystąpienia reakcji alergicznych i
              zapobiegać rozprzestrzenianiu się chorób zakaźnych.
            </p>
            <p>
              Po drugie, dywany i wykładziny są narażone na różnego rodzaju
              plamy i zabrudzenia, takie jak olej, kawa czy wino. Wraz z czasem,
              te plamy stają się trudniejsze do usunięcia. Regularne pranie może
              pomóc w usunięciu tych plam i utrzymać dywan czy wykładzinę w
              dobrym stanie przez dłuższy czas.
            </p>
            <img
              className="quilon"
              src={regul}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              Po trzecie, regularne pranie dywanów i wykładzin może pomóc w
              utrzymaniu ich wyglądu i trwałości. Z czasem, na powierzchniach
              tych mogą gromadzić się zanieczyszczenia, które mogą wpłynąć na
              ich strukturę i trwałość. Pranie pozwala usunąć te
              zanieczyszczenia i poprawić jakość powierzchni, co pozytywnie
              wpływa na wygląd i trwałość dywanów i wykładzin.
            </p>
            <p>
              Po czwarte, pranie dywanów i wykładzin to także kwestia zapachów.
              Z czasem, na powierzchniach tych mogą gromadzić się zapachy z
              otoczenia, zwłaszcza w miejscach, gdzie palimy papierosy,
              gotujemy, czy hodujemy zwierzęta. Regularne pranie pozwala usunąć
              te zapachy i odświeżyć powierzchnie.
            </p>
            <p>
              Podsumowując, pranie dywanów i wykładzin to ważna czynność, która
              wpływa na czystość i higienę naszych domów. Regularne pranie
              pozwala usunąć zanieczyszczenia, plamy i zapachy, a także utrzymać
              dywany i wykładziny w dobrym stanie przez dłuższy czas. Dlatego
              warto zadbać o regularne pranie, aby zapewnić sobie czyste i
              zdrowe środowisko w naszych domach.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
