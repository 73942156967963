import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto3.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel22">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Jak samodzielnie wyczyścić sierść zwierząt w domowych warunkach
              Sosnowiec
            </h2>
            <p>
              Posiadanie zwierząt domowych to ogromna radość, ale wiąże się
              również z pewnymi wyzwaniami, takimi jak regularne sprzątanie
              sierści. Szczególnie w okresie linienia, sierść może gromadzić się
              na meblach, podłogach i ubraniach.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Poniżej przedstawiam kilka skutecznych metod, które pomogą w
              utrzymaniu czystości w domu.
            </p>
            <ul className="decical-ol">
              <li>
                Regularne odkurzanie. Odkurzanie to podstawowy sposób na
                usuwanie sierści. Warto zainwestować w odkurzacz wyposażony w
                specjalne końcówki do zbierania sierści. Odkurzanie powinno być
                regularne, najlepiej kilka razy w tygodniu, aby zapobiec
                gromadzeniu się sierści w dużych ilościach.
              </li>
              <li>
                Szczotki i wałki do ubrań. Szczotki do ubrań i wałki z taśmą
                klejącą są niezastąpione w walce z sierścią na meblach i
                ubraniach. Wałki doskonale zbierają sierść z tkanin, a ich
                użycie jest proste i szybkie. Alternatywnie można użyć gumowych
                rękawiczek – wystarczy przetrzeć nimi powierzchnię, aby sierść
                przywarła do gumy.
              </li>
              <li>
                Pranie z dodatkiem specjalnych środków. Pranie ubrań i
                tekstyliów z dodatkiem środków antystatycznych lub specjalnych
                płynów do prania pomoże w usuwaniu sierści. Przed praniem warto
                potrząsnąć ubraniami, aby pozbyć się nadmiaru sierści. W
                przypadku koców czy narzut można użyć suszarki bębnowej z opcją
                delikatnego suszenia, co również pomoże zebrać sierść.
              </li>
              <li>
                Nawilżone chusteczki. Nawilżone chusteczki dla dzieci lub
                specjalne chusteczki do czyszczenia sierści mogą być bardzo
                pomocne. Szybko i skutecznie zbierają sierść z różnych
                powierzchni, a dodatkowo odświeżają tkaniny.
              </li>
              <li>
                Regularna pielęgnacja zwierząt. Regularne szczotkowanie zwierząt
                znacząco redukuje ilość sierści, która opada na podłogę i meble.
                W zależności od rasy i rodzaju sierści, warto dobrać odpowiednią
                szczotkę i szczotkować zwierzaka co najmniej raz w tygodniu.
              </li>
              <li>
                Używanie oczyszczaczy powietrza. Oczyszczacze powietrza z
                filtrami HEPA są skuteczne w zbieraniu unoszących się w
                powietrzu alergenów i sierści. Pomagają one również w utrzymaniu
                lepszej jakości powietrza w domu.
              </li>
              <li>
                Pokrowce i narzuty ochronne. Stosowanie pokrowców na meble i
                narzut ochronnych ułatwia utrzymanie czystości. Pokrowce można
                regularnie prać, co ułatwia pozbycie się sierści i zapobiega jej
                gromadzeniu się na meblach.
              </li>
            </ul>
            <p>
              Podsumowując, regularne stosowanie powyższych metod pomoże w
              utrzymaniu czystości w domu i zminimalizuje problem sierści
              zwierząt. Ważne jest, aby nie zaniedbywać pielęgnacji zwierząt, co
              również przyczyni się do redukcji ilości sierści w domu.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
