import React, { useState } from 'react';
import FiveScreen from '../../FiveScreen/FiveScreen';
import glass from '../../img/HopClean/contacts.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';

import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';

import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articelthree">En</Link>
          ) : (
            <Link to="/articelthree">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>
              Jak często należy czyścić meble tapicerowane w biurze Sosnowiec
            </h2>
            <p>
              Meble tapicerowane są popularnym wyborem dla biur ze względu na
              ich wygodę i estetykę. Jednak, aby zachować ich czystość i
              trwałość, należy regularnie je czyścić. W tym artykule omówimy,
              jak często należy czyścić meble tapicerowane w biurze i dlaczego
              jest to ważne.
            </p>
            <p>
              Przede wszystkim, warto pamiętać, że meble tapicerowane są podatne
              na gromadzenie się kurzu, brudu, roztoczy i innych alergenów. Te
              substancje mogą wpływać na jakość powietrza w biurze, a także
              prowadzić do alergii i innych problemów zdrowotnych. Dlatego tak
              ważne jest regularne czyszczenie mebli tapicerowanych.
            </p>
            <p>
              Ogólnie, zaleca się, aby meble tapicerowane w biurze były
              czyszczone co najmniej raz na kwartał. Jednak, jeśli w biurze jest
              dużo ruchu, a meble są intensywnie używane, może to wymagać
              częstszego czyszczenia. W takim przypadku zaleca się czyszczenie
              mebli co najmniej raz na miesiąc.
            </p>
            <img
              className="quilon"
              src={glass}
              title="фурнітура"
              alt="фурнітура"
            />
            <p>
              Jeśli chodzi o metodę czyszczenia, istnieje wiele sposobów, w tym
              odkurzanie, pranie na sucho, pranie na mokro i czyszczenie parowe.
              Wybór odpowiedniej metody zależy od rodzaju tapicerki, stopnia
              zabrudzenia oraz preferencji i budżetu biura. W przypadku
              intensywnie zabrudzonych mebli zaleca się skorzystanie z usług
              profesjonalnych firm czyszczących.
            </p>
            <p>
              Ważne jest również, aby regularnie usuwać plamy z mebli
              tapicerowanych. W przypadku większych plam zaleca się jak
              najszybsze ich usunięcie, aby zapobiec trudniejszym do usunięcia
              plamom i zabrudzeniom. Do usuwania plam można użyć specjalnych
              środków czyszczących lub domowych metod, takich jak wykorzystanie
              wody z mydłem lub roztworu octu.
            </p>
            <p>
              Podsumowując, meble tapicerowane w biurze należy regularnie
              czyścić, aby zachować ich czystość i trwałość, a także zapobiec
              gromadzeniu się kurzu, brudu i alergenów. Zaleca się czyszczenie
              mebli co najmniej raz na kwartał, ale jeśli meble są intensywnie
              używane, może to wymagać częstszego czyszczenia. Wybór
              odpowiedniej metody czyszczenia zależy od rodzaju tapicerki i
              stopnia zabrudzenia.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
