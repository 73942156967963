import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/articel12.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel12">En</Link>
          ) : (
            <Link to="/articel12">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie wózków dziecięcych i fotelików Sosnowiec </h2>
            <p>
              Pranie wózków dziecięcych i fotelików samochodowych to ważna
              czynność, która wpływa na higienę i bezpieczeństwo naszych dzieci.
              Pomimo dbałości o czystość i regularnego mycia, na powierzchniach
              tych mogą gromadzić się różnego rodzaju zanieczyszczenia, które
              wpływają nie tylko na estetykę, ale również na zdrowie naszych
              pociech. W tym artykule przedstawimy kilka powodów, dla których
              pranie wózków i fotelików jest tak istotne.
            </p>
            <p>
              Po pierwsze, dzieci w wieku niemowlęcym są szczególnie wrażliwe na
              zanieczyszczenia. Ich skóra jest delikatna i podatna na
              podrażnienia, a układ odpornościowy jeszcze nie jest w pełni
              rozwinięty. Dlatego ważne jest, aby chronić je przed bakteriami,
              wirusami i innymi szkodliwymi substancjami, które mogą znajdować
              się na powierzchniach wózków i fotelików.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              Po drugie, wózki dziecięce i foteliki samochodowe są narażone na
              różnego rodzaju plamy i zabrudzenia, takie jak mleko, jedzenie czy
              płyny. Te zabrudzenia mogą wpływać na trwałość i funkcjonalność
              wózków i fotelików oraz negatywnie wpływać na zdrowie naszych
              dzieci. Regularne pranie pozwala usunąć te plamy i utrzymać wózki
              i foteliki w dobrym stanie przez dłuższy czas.
            </p>
            <p>
              Po trzecie, wózki dziecięce i foteliki samochodowe mogą mieć wiele
              elementów, które trudno jest wyczyścić bezpośrednio na miejscu,
              takie jak pasy bezpieczeństwa, ramy, czy poduszki. Pranie wózków i
              fotelików pozwala na dokładne wyczyszczenie wszystkich elementów,
              co wpływa na higienę i bezpieczeństwo naszych dzieci.
            </p>
            <p>
              Po czwarte, regularne pranie wózków i fotelików pozwala na
              utrzymanie świeżego i przyjemnego zapachu, co jest szczególnie
              ważne w przypadku wózków dziecięcych i fotelików samochodowych,
              które często są narażone na różnego rodzaju zapachy, np. z
              jedzenia czy płynów.{' '}
            </p>
            <p>
              Podsumowując, pranie wózków dziecięcych i fotelików samochodowych
              to ważna czynność, która wpływa na higienę i bezpieczeństwo
              naszych dzieci. Regularne pranie pozwala usunąć zanieczyszczenia i
              plamy, utrzymać elementy wózków i fotelików w dobrym stanie oraz
              zachować świeży i przyjemny zapach. Dlatego warto dbać o regularne
              pranie wózków i fotelików, aby zapewnić naszym dzieciom czyste.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
