import FiveScreen from '../../FiveScreen/FiveScreen';
import window from './../../img/HopClean/updateArticles/foto5.jpg';
import './../Articels/Articeles.css';
import { Cataloge } from './ArticelOne';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FirstScreen from '../../FirstScreen/ContainerOne';
import '../../Pages/Services.css';
import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articel20">En</Link>
          ) : (
            <Link to="/articel13">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}
function Furnitura() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Jak samodzielnie wyczyścić zasłony w domu Sosnowiec </h2>
            <p>
              Zasłony pełnią nie tylko funkcję dekoracyjną, ale również chronią
              nas przed słońcem i zapewniają prywatność. Niestety, z czasem
              gromadzą kurz, brud i alergeny. Regularne czyszczenie zasłon jest
              zatem niezbędne, aby zachować świeżość i higienę w domu.
            </p>
            <img className="window" src={window} title="window" alt="window" />
            <p>
              {' '}
              Oto kilka kroków, jak samodzielnie wyczyścić zasłony w domowych
              warunkach.
            </p>
            <ul className="decical-ol">
              <li>
                Sprawdź metkę. Przed rozpoczęciem czyszczenia zasłon, koniecznie
                sprawdź metkę z instrukcjami dotyczącymi prania. Producenci
                często umieszczają na niej informacje o zalecanych metodach
                czyszczenia. Niektóre zasłony można prać w pralce, inne wymagają
                delikatniejszego podejścia, na przykład prania ręcznego lub
                chemicznego czyszczenia.
              </li>
              <li>
                Odkurzanie. Przed praniem warto dokładnie odkurzyć zasłony, aby
                usunąć z nich kurz i luźne zanieczyszczenia. Użyj końcówki do
                odkurzacza przeznaczonej do tapicerki lub miękkiej szczotki.
                Odkurzanie można wykonywać regularnie, co kilka tygodni, aby
                zasłony zawsze wyglądały świeżo.
              </li>
              <li>
                Pranie w pralce. Jeśli metka pozwala na pranie w pralce, zdejmij
                zasłony i włóż je do bębna. Wybierz delikatny program prania i
                użyj łagodnego detergentu. Zaleca się pranie zasłon w niskiej
                temperaturze (30-40°C), aby uniknąć skurczenia tkaniny. Po
                zakończeniu prania wyjmij zasłony natychmiast, aby uniknąć
                zagnieceń.
              </li>
              <li>
                Pranie ręczne. W przypadku delikatnych zasłon, które nie nadają
                się do prania w pralce, zastosuj pranie ręczne. Napełnij wannę
                lub dużą miskę letnią wodą i dodaj łagodny detergent. Delikatnie
                zanurz zasłony i pozostaw je na kilka minut, aby brud się
                rozpuścił. Następnie delikatnie przepłucz zasłony czystą wodą,
                aż detergent zostanie całkowicie usunięty.
              </li>
              <li>
                Suszenie. Po praniu zasłony należy odpowiednio wysuszyć. Unikaj
                suszenia w suszarce bębnowej, ponieważ może to spowodować
                skurczenie tkaniny lub zniszczenie delikatnych materiałów.
                Najlepiej suszyć zasłony na powietrzu, rozwieszając je na
                sznurku lub suszarce. Upewnij się, że są równomiernie rozłożone,
                aby uniknąć zagnieceń.
              </li>
              <li>
                Prasowanie. Jeżeli zasłony po wysuszeniu są pogniecione, możesz
                je delikatnie wyprasować. Użyj żelazka ustawionego na niską
                temperaturę i prasuj zasłony na lewej stronie, aby uniknąć
                uszkodzenia materiału. W przypadku zasłon z delikatnych tkanin,
                takich jak jedwab, warto użyć specjalnej nakładki na żelazko lub
                prasować przez bawełnianą ściereczkę.
              </li>
              <li>
                Czyszczenie chemiczne. Jeśli zasłony są wykonane z bardzo
                delikatnych materiałów lub są zbyt duże, aby prać je w domu,
                warto oddać je do profesjonalnej pralni chemicznej. Specjaliści
                dokładnie wyczyszczą zasłony, dbając o zachowanie ich
                pierwotnego wyglądu i jakości.
              </li>
            </ul>
            <p>
              Samodzielne czyszczenie zasłon w domu nie jest trudne, wymaga
              jedynie przestrzegania kilku podstawowych zasad. Regularne
              odkurzanie, pranie zgodnie z instrukcjami na metce oraz
              odpowiednie suszenie i prasowanie zapewnią, że zasłony będą
              wyglądać jak nowe przez długi czas. Dbając o zasłony, dbamy
              również o zdrowie i komfort naszego domu.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}
function ArticelTwo() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Furnitura />
      <FiveScreen />
    </div>
  );
}
export default ArticelTwo;
