import React, { useEffect, useState } from 'react';
import quilon from '../../img/HopClean/articel.jpg';
import quilon2 from '../../img/HopClean/galler9.jpg';
import './../Articels/Articeles.css';

import FirstScreen from '../../FirstScreen/ContainerOne';
import FiveSCreen from '../../FiveScreen/FiveScreen';
import '../../Pages/Services.css';

import '../../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { NavLink, Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/articelone">En</Link>
          ) : (
            <Link to="/articelone">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };
  const toggle2 = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Katalog</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink to="/articelone">
            <span>Pranie tapicerki meblowej</span>
          </NavLink>
        </li>
        <li onClick={toggle2}>
          <NavLink to="/articeltwo">
            <span>Pranie materacy</span>
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articelthree">
            Jak często należy czyścić meble tapicerowane w biurze
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articelfour">
            Zalecana częstotliwość czyszczenia materaca
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articelfive">Pranie dywanów i wykładzin </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articelsix">
            {' '}
            Сzyszczenie materacy w przedszkolach{' '}
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articelseven"> Pranie mebli w samolocie </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel8">
            {' '}
            Jak często należy czyścić dywan w biurze
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel9">Pranie zasłon, firan, rolet</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel10">
            Pranie i czyszczenie mebli tapicerowanych na jachtach i łodziach
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel11">Pranie tapicerki samochodowej</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel12">
            Pranie wózków dziecięcych i fotelików{' '}
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel13">
            Czym wyczyścić sofy i krzesła tapicerowane?
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel14">Czyszczenie Tapicerki skórzanej</NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel15">
            Jak samodzielnie wyczyścić meble skórzane
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel16">
            Jak samodzielnie wyczyścić dywan w warunkach domowych
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel17">
            Jak samodzielnie wyczyścić meble tapicerowane w domowych warunkach?
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel18">
            Jak samodzielnie wyczyścić materac w domowych warunkach
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel19">
            Jak samodzielnie wyczyścić pluszowe zabawki w warunkach domowych
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel20">
            Jak samodzielnie wyczyścić zasłony w domu
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel21">
            Jak samodzielnie wyczyścić sufit w samochodzie w domowych warunkach
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel22">
            Jak samodzielnie wyczyścić sierść zwierząt w domowych warunkach
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink to="/articel23">
            Jak samodzielnie wyczyścić dziecięcy wózek w domu?
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie tapicerki meblowej Sosnowiec</h2>

            <p>
              Pranie mebli tapicerowanych to jedna z ważniejszych czynności
              związanych z utrzymaniem higieny w naszych domach czy miejscach
              pracy. Meble tapicerowane są nie tylko miejscem do wypoczynku, ale
              także często służą jako powierzchnia do pracy czy jedzenia. W
              związku z tym, zbierają wiele zabrudzeń, bakterii i roztoczy,
              które mogą wpłynąć negatywnie na nasze zdrowie.
            </p>
            <p>
              Jednym z najważniejszych powodów, dla których warto regularnie
              prać meble tapicerowane, jest usunięcie z nich zabrudzeń. Meble
              tapicerowane, zwłaszcza te w jasnych kolorach, szybko zaczynają
              wyglądać brudno i zniszczone. Zabrudzenia, takie jak plamy po
              jedzeniu, plamy z oleju czy zaschnięte resztki jedzenia, są bardzo
              trudne do usunięcia z powierzchni tapicerki. Regularne pranie może
              pomóc w usunięciu tych zabrudzeń i przywrócić meblom ich pierwotny
              wygląd.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Drugim ważnym powodem do prania mebli tapicerowanych jest
              zapobieganie rozwojowi bakterii i grzybów. W przypadku
              zanieczyszczeń i wilgoci, na powierzchni mebli tapicerowanych mogą
              rozwijać się grzyby i bakterie, które mogą powodować choroby i
              alergie. Pranie mebli tapicerowanych może pomóc w usunięciu tych
              mikroorganizmów, co z kolei wpłynie korzystnie na nasze zdrowie.
            </p>
            <p>
              Trzecim powodem, dla którego warto prać meble tapicerowane, jest
              usuwanie roztoczy. Roztocza to małe organizmy, które żyją w
              kurzach i zanieczyszczeniach na powierzchni mebli. Są one
              przyczyną alergii u wielu osób, szczególnie tych, które mają
              problemy z układem oddechowym. Regularne pranie mebli
              tapicerowanych może pomóc w usunięciu roztoczy, co z kolei poprawi
              jakość powietrza w naszych domach.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Podsumowując, pranie mebli tapicerowanych jest ważne dla
              utrzymania higieny w naszych domach i miejscach pracy. Pomaga w
              usuwaniu zabrudzeń, bakterii i roztoczy, które mogą wpłynąć
              negatywnie na nasze zdrowie. Regularne pranie mebli tapicerowanych
              może pomóc w utrzymaniu ich wyglądu i jakości na dłużej.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function ArticelOne() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Quilon />
      <FiveSCreen />
    </div>
  );
}
export default ArticelOne;
